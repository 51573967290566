import Encabezado from './experiencia/Encabezado'
import foto from '../assets/mountains.jpg'
import { useContext, useEffect } from 'react'
import CategoriaContext from '../context/categoria/CategoriaContext'
import PaqueteContext from '../context/paquete/PaqueteContext'
import { useParams } from 'react-router-dom'
import Experiencias from './inicio/Experiencias'
import Casco from '../components/Casco'

const Categoria = () => {
  const categoriaContext = useContext(CategoriaContext)
  const { categoria, getCategoria } = categoriaContext

  const paqueteContext = useContext(PaqueteContext)
  const { paquetes, loading, getPaquetes } = paqueteContext

  const { id } = useParams()

  useEffect(() => {
    getCategoria(id)
    getPaquetes(id)
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      <Casco
        titulo={Object.keys(categoria).length > 0 && `${categoria.nombre}s`}
      />

      <Encabezado
        nombre={Object.keys(categoria).length > 0 ? `${categoria.nombre}s` : ''}
        foto={foto}
      />
      <Experiencias
        titulo="Experiencias"
        listado={paquetes}
        loading={loading}
      />
    </>
  )
}

export default Categoria
