import './Spinner.css'

const Spinner = () => {
  return (
    <div className="col-12">
      <div className="mx-auto">
        <div className="spinner">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
      </div>
    </div>
  )
}

export default Spinner
