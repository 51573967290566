export const HANDLE_CLEAN = 'HANDLE_CLEAN'
export const HANDLE_SUCCESS = 'HANDLE_SUCCESS'
export const HANDLE_ERROR = 'HANDLE_ERROR'

// PAQUETES
export const GET_PAQUETES_BOY = 'GET_PAQUETES_BOY'
export const GET_PAQUETES_COL = 'GET_PAQUETES_COL'
export const GET_PAQUETES_ALO = 'GET_PAQUETES_ALO'
export const GET_PAQUETES_PROX = 'GET_PAQUETES_PROX'
export const GET_PAQUETES_BUSQ = 'GET_PAQUETES_BUSQ'
export const GET_PAQUETES_DETAIL = 'GET_PAQUETES_DETAIL'
export const GET_PAQUETES = 'GET_PAQUETES'
export const GET_PAQUETE = 'GET_PAQUETE'

// CATEGORIAS
export const GET_CATEGORIAS = 'GET_CATEGORIAS'
export const GET_CATEGORIA = 'GET_CATEGORIA'

// USERS
export const USER_REGISTER = 'USER_REGISTER'
export const USER_GET = 'USER_GET'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_REFRESH = 'USER_REFRESH'
export const USER_CHANGE = 'USER_CHANGE'
export const USER_REQUEST = 'USER_REQUEST'
export const USER_RESET = 'USER_RESET'

// DESTINOS
export const GET_DESTINOS = 'GET_DESTINOS'
export const GET_DESTINO = 'GET_DESTINO'

// MIPLAN
export const GET_MIPLAN = 'GET_MIPLAN'
export const GET_RESERVA = 'GET_RESERVA'
export const GET_RESERVAS = 'GET_RESERVAS'
export const GET_RESERVASPLAN = 'GET_RESERVASPLAN'
export const CREATE_MIPLAN = 'CREATE_MIPLAN'
export const CLEAN_MIPLAN = 'CLEAN_MIPLAN'

// COMENTARIOS
export const GET_COMMENTS = 'GET_COMMENTS'
export const GET_COMMENTARIO = 'GET_COMMENTARIO'
export const GET_TESTIMONIOS = 'GET_TESTIMONIOS'
export const CREATE_COMMENT = 'CREATE_COMMENT'

// EMAIL
export const SEND_EMAIL = 'SEND_EMAIL'

// ENTRADA
export const GET_ENTRADAS = 'GET_ENTRADAS'
export const GET_ENTRADA = 'GET_ENTRADA'
