const SliderPages = ({ clase = '', foto }) => {
  const { data } = foto

  return (
    <div className={`carousel-item ${clase} `} data-interval="2000">
      <img
        src={data.full_url}
        className="d-block w-100 carousel-img rounded-lg"
        alt="paquete"
        loading="lazy"
      />
    </div>
  )
}

export default SliderPages
