import { Link } from 'react-router-dom'

const Card = ({ entrada }) => {
  const { id, created_on, titulo, descripcion, imagen } = entrada
  const { data } = imagen

  const fecha = new Date(created_on)

  return (
    <div className="col-xl-6 col-lg-6 col-md-6">
      <Link to={`/blog/${id}`}>
        <div className="home-blog-single mb-30">
          <div className="blog-img-cap">
            <div className="blog-img">
              <img src={data.thumbnails[4].url} alt={titulo} loading="lazy" />
            </div>
            <div className="blog-cap">
              <p>{descripcion}</p>
              <h3>{titulo}</h3>
              <p className="more-btn">Leer Más»</p>
            </div>
          </div>
          <div className="blog-date text-center">
            <span>
              {fecha.getDate()}/{fecha.getDay()}
            </span>
            <p>{fecha.getFullYear()}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card
