import {
  CLEAN_MIPLAN,
  CREATE_MIPLAN,
  GET_MIPLAN,
  GET_RESERVA,
  GET_RESERVAS,
  GET_RESERVASPLAN,
  HANDLE_CLEAN,
} from '../../types'

const MiplanReducer = (state, action) => {
  switch (action.type) {
    case GET_MIPLAN:
      return {
        ...state,
        listado: action.payload,
        loading: false,
      }
    case CREATE_MIPLAN:
      return {
        ...state,
        plan: action.payload,
      }
    case CLEAN_MIPLAN:
      return {
        ...state,
        plan: [],
      }
    case GET_RESERVA:
      return {
        ...state,
        reserva: action.payload,
        loadingR: false,
      }
    case GET_RESERVAS:
      return {
        ...state,
        reservas: action.payload,
        loadingRes: false,
      }
    case GET_RESERVASPLAN:
      return {
        ...state,
        reservasPlan: action.payload,
      }
    case HANDLE_CLEAN:
      return {
        ...state,
        reseervasPlan: [],
      }
    default:
      break
  }
}

export default MiplanReducer
