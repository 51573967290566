const BottomSection = ({ paquete }) => {
  const { descripcion } = paquete
  return (
    <>
      <h1>Descripción:</h1>
      <div
        className="wysiwyg"
        dangerouslySetInnerHTML={{ __html: descripcion }}
      />
    </>
  )
}

export default BottomSection
