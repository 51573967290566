import { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from '../context/auth/AuthContext'

const PrivateRoute = ({ component: Component, ...props }) => {
  const authContext = useContext(AuthContext)
  const { autenticado, loading, getUser } = authContext

  useEffect(() => {
    getUser()
    //eslint-disable-next-line
  }, [])

  return (
    <Route
      {...props}
      render={(props) =>
        !autenticado && !loading ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default PrivateRoute
