import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PaqueteContext from '../context/paquete/PaqueteContext'
import Encabezado from './experiencia/Encabezado'
import Experiencias from './inicio/Experiencias'
import mountains from '../assets/mountains.jpg'
import Casco from '../components/Casco'

const Detalle = () => {
  const paqueteContext = useContext(PaqueteContext)
  const { paquetesDet, loadingDet, getPaquetesDetail } = paqueteContext

  const { id } = useParams()

  useEffect(() => {
    getPaquetesDetail(id)
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      <Casco titulo="Experiencias" />
      <Encabezado nombre="Experiencias" foto={mountains} />
      <Experiencias
        titulo="Experiencias"
        listado={paquetesDet}
        loading={loadingDet}
      />
    </>
  )
}

export default Detalle
