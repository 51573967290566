import { useContext, useEffect } from 'react'
import Casco from '../components/Casco'
import PaqueteContext from '../context/paquete/PaqueteContext'
import Aliados from './inicio/Aliado'
import Experiencias from './inicio/Experiencias'
import Header from './inicio/Header'
import MediosPago from './inicio/MediosPago'
import Testimonios from './inicio/Testimonios'

const Inicio = () => {
  const paqueteContext = useContext(PaqueteContext)
  const {
    paquetesBoy,
    paquetesCol,
    paquetesAlo,
    paquetesProx,
    loadingBoy,
    loadingCol,
    loadingAlo,
    loadingProx,
    getPaquetesInit,
  } = paqueteContext

  useEffect(() => {
    getPaquetesInit()
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Casco />
      <Header />
      <Experiencias
        titulo="Viaja por Boyacá"
        listado={paquetesBoy}
        loading={loadingBoy}
        link="boyaca"
      />
      <Experiencias
        titulo="Viaja por Colombia"
        listado={paquetesCol}
        loading={loadingCol}
        clase="bg-light2"
        link="colombia"
      />
      <Experiencias
        titulo="Alojamientos"
        listado={paquetesAlo}
        loading={loadingAlo}
        link="alojamientos"
      />
      <Experiencias
        titulo="Experiencias Próximas a Salir"
        listado={paquetesProx}
        loading={loadingProx}
        clase="bg-light2"
      />
      <Aliados />
      <Testimonios />
      <MediosPago />
    </>
  )
}

export default Inicio
