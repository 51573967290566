const Oferta = ({ porcentaje, costo }) => {
  const oferta = porcentaje / 100
  const rebaja = costo * oferta

  const total = costo - rebaja

  const precio_Antes = new Intl.NumberFormat('es-ES').format(costo)
  const precio_Oferta = new Intl.NumberFormat('es-ES').format(total)

  return (
    <>
      <h3>
        Antes: <del>${precio_Antes} pesos</del>
      </h3>
      <h3>Ahora: ${precio_Oferta} pesos</h3>
    </>
  )
}

export default Oferta
