import { useContext } from 'react'
import Casco from '../../components/Casco'
import Comentarios from '../../components/Comentarios'
import CommentForm from '../../components/CommentForm'
import AuthContext from '../../context/auth/AuthContext'
import BottomSection from './BottomSection'
import Encabezado from './Encabezado'
import RightSection from './RightSection'
import Slider from './Slider'
import SliderPages from './SliderPages'

const Detalle = ({ paquete }) => {
  const { foto, fotos } = paquete

  const authContext = useContext(AuthContext)
  const { user } = authContext

  return (
    <>
      <Casco titulo={paquete.nombre} />
      <Encabezado nombre={paquete.nombre} foto={paquete.foto.data.full_url} />
      <div className="container">
        <div className="row shadow-lg  pt-2 pb-2">
          <div className="col-lg-7">
            <Slider>
              <SliderPages clase="active" foto={foto} />
              {fotos.map(({ directus_files_id }) => (
                <SliderPages
                  key={directus_files_id.id}
                  foto={directus_files_id}
                />
              ))}
            </Slider>
          </div>

          <div className="col-lg-5">
            <hr />
            <RightSection datos={paquete} />
          </div>
          <div className="col-lg-12">
            <hr />
            <BottomSection paquete={paquete} />
          </div>
          <div className="col-lg-12">
            <hr />
            <CommentForm paquete={paquete.id} owner={user.id} />
            <Comentarios paquete={paquete.id} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Detalle
