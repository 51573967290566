import Encabezado from './experiencia/Encabezado'
import mountains from '../assets/mountains.jpg'
import NosotrosContent from './nosotros/NosotrosContent'
import Casco from '../components/Casco'

const Nosotros = () => {
  return (
    <>
      <Casco titulo="Nosotros" />
      <Encabezado foto={mountains} nombre={`Nosotros`} />
      <div className="container p-5">
        <div className="row">
          <NosotrosContent />
        </div>
      </div>
    </>
  )
}

export default Nosotros
