import { useContext, useEffect } from 'react'
import Spinner from '../../components/Spinner'
import AuthContext from '../../context/auth/AuthContext'
import MiplanContext from '../../context/miPlan/MiplanContext'
import AdminItems from './AdminItems'

const AdminReservasContainer = () => {
  const miplanContext = useContext(MiplanContext)
  const { reservas, loadingRes, getReservas } = miplanContext

  const authContext = useContext(AuthContext)
  const { user } = authContext
  const { id } = user

  useEffect(() => {
    getReservas(id)
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      {loadingRes ? (
        <Spinner />
      ) : Object.keys(reservas).length > 0 ? (
        reservas.map((compra) => <AdminItems key={compra.id} compra={compra} />)
      ) : (
        <h2>No hay Reservas</h2>
      )}
    </>
  )
}

export default AdminReservasContainer
