import mountains from '../assets/mountains.jpg'
import Casco from '../components/Casco'
import Encabezado from './experiencia/Encabezado'
import PagosContainer from './misreservas/PagosContainer'

const PagosHist = () => {
  return (
    <>
      <Casco titulo="Historial de Pagos" />
      <Encabezado foto={mountains} nombre={`Historial de Pagos`} />
      <div className="container shadow-lg p-5">
        <div className="row">
          <div className="col-lg-12 p-3">
            <PagosContainer />
          </div>
        </div>
      </div>
    </>
  )
}

export default PagosHist
