import { Link } from 'react-router-dom'

const Card = ({ datos }) => {
  const {
    id,
    nombre,
    costo,
    ciudad,
    categoria,
    foto,
    viajeros_rebaja,
    oferta,
    porcentaje_oferta,
  } = datos

  const ofertaR = porcentaje_oferta / 100
  const rebaja = costo * ofertaR

  const total = costo - rebaja

  const precio_Antes = new Intl.NumberFormat('es-ES').format(costo)
  const precio_Oferta = new Intl.NumberFormat('es-ES').format(total)

  return (
    <div className="col-xl-4 col-lg-4 col-md-6">
      <Link to={`/experiencia/${id}`}>
        <div className="single-place mb-30 bg-light rounded-lg tarjeta">
          <div className="place-img">
            <img
              src={foto.data.thumbnails[2].url}
              alt={foto.title}
              loading="lazy"
            />
          </div>
          <div className="place-cap">
            <div className="place-cap-top">
              {oferta ? (
                <span>
                  <i className="fas fa-tag"></i>
                  <span>
                    En Oferta - Antes: <del>${precio_Antes}</del>
                  </span>
                </span>
              ) : null}
              <h3>{nombre}</h3>
              <p className="dolor">
                {oferta ? (
                  <>
                    ${precio_Oferta} <span>/ Por Persona</span>
                  </>
                ) : (
                  <>
                    ${precio_Antes} <span>/ Por Persona</span>
                  </>
                )}
                {viajeros_rebaja ? (
                  <>
                    <br />
                    <span>Tarifa dinámica</span>
                  </>
                ) : null}
              </p>
            </div>
            <div className="place-cap-bottom">
              <ul>
                <li>
                  <i className="far fa-clock"></i>
                  {categoria.nombre}
                </li>
                <li>
                  <i className="fas fa-map-marker-alt"></i>
                  {ciudad.nombre}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card
