import { useReducer } from 'react'
import clienteAxios from '../../config/clienteAxios'
import {
  GET_PAQUETE,
  GET_PAQUETES,
  GET_PAQUETES_ALO,
  GET_PAQUETES_BOY,
  GET_PAQUETES_COL,
  GET_PAQUETES_PROX,
  GET_PAQUETES_BUSQ,
  GET_PAQUETES_DETAIL,
  HANDLE_CLEAN,
} from '../../types'
import PaqueteContext from './PaqueteContext'
import PaqueteReducer from './PaqueteReducer'

const PaqueteState = (props) => {
  const initialState = {
    paquetesBoy: [],
    paquetesCol: [],
    paquetesAlo: [],
    paquetesProx: [],
    paquetesBus: [],
    paquetesDet: [],
    paquetes: [],
    paquete: [],
    loadingBoy: true,
    loadingCol: true,
    loadingAlo: true,
    loadingProx: true,
    loadingBus: true,
    loadingDet: true,
    loading: true,
  }

  const [state, dispatch] = useReducer(PaqueteReducer, initialState)

  const getPaquetesInit = () => {
    getPaquetesBoy()
    getPaquetesCol()
    getPaquetesAlo()
    getPaquetesProxim()
  }

  const getPaquetesBoy = async () => {
    try {
      const res = await clienteAxios.get(
        'items/paquete?filter[ciudad.departamento.nombre][eq]=boyaca&fields=*.*.*&limit=6&sort=-created_on&filter[publico][eq]=1'
      )
      dispatch({
        type: GET_PAQUETES_BOY,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getPaquetesCol = async () => {
    try {
      const res = await clienteAxios.get(
        'items/paquete?filter[ciudad.departamento.nombre][neq]=boyaca&fields=*.*.*&limit=6&sort=-created_on&filter[publico][eq]=1'
      )
      dispatch({
        type: GET_PAQUETES_COL,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getPaquetesAlo = async () => {
    try {
      const res = await clienteAxios.get(
        'items/paquete?filter[categoria.nombre][eq]=alojamiento&fields=*.*.*&limit=6&sort=-created_on&filter[publico][eq]=1'
      )
      dispatch({
        type: GET_PAQUETES_ALO,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getPaquetesProxim = async () => {
    try {
      const res = await clienteAxios.get(
        'items/paquete?filter[fecha][nnull]&sort=-fecha&limit=6&fields=*.*.*&limit=6&filter[publico][eq]=1'
      )
      dispatch({
        type: GET_PAQUETES_PROX,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getPaquetesDetail = async (cate) => {
    handleClean()
    let url = ''
    switch (cate) {
      case 'boyaca':
        url =
          'items/paquete?filter[ciudad.departamento.nombre][eq]=boyaca&fields=*.*.*&limit=-1&sort=-created_on&filter[publico][eq]=1'
        break
      case 'colombia':
        url =
          'items/paquete?filter[ciudad.departamento.nombre][neq]=boyaca&fields=*.*.*&limit=-1&sort=-created_on&filter[publico][eq]=1'
        break
      case 'alojamientos':
        url =
          'items/paquete?filter[categoria.nombre][eq]=alojamiento&fields=*.*.*&limit=-1&sort=-created_on&filter[publico][eq]=1'
        break
      default:
        break
    }
    try {
      const res = await clienteAxios.get(url)
      dispatch({
        type: GET_PAQUETES_DETAIL,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getPaquetesBusqueda = async (dpto, categ) => {
    handleClean()
    try {
      const res = await clienteAxios.get(
        `items/paquete?filter[ciudad.departamento][eq]=${dpto}&filter[categoria][eq]=${categ}&fields=*.*.*&limit=-1&sort=-created_on&filter[publico][eq]=1`
      )
      dispatch({
        type: GET_PAQUETES_BUSQ,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getPaquete = async (id) => {
    handleClean()
    try {
      const res = await clienteAxios.get(`items/paquete/${id}?fields=*.*.*`)
      dispatch({
        type: GET_PAQUETE,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getPaquetes = async (id) => {
    handleClean()
    try {
      const res = await clienteAxios.get(
        `items/paquete?filter[categoria][eq]=${id}&fields=*.*.*&limit=-1&sort=-created_on&filter[publico][eq]=1`
      )
      dispatch({
        type: GET_PAQUETES,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const handleClean = () => {
    dispatch({
      type: HANDLE_CLEAN,
    })
  }

  return (
    <PaqueteContext.Provider
      value={{
        paquetesBoy: state.paquetesBoy,
        paquetesCol: state.paquetesCol,
        paquetesAlo: state.paquetesAlo,
        paquetesProx: state.paquetesProx,
        paquetesBus: state.paquetesBus,
        paquetesDet: state.paquetesDet,
        loadingBoy: state.loadingBoy,
        loadingCol: state.loadingCol,
        loadingAlo: state.loadingAlo,
        loadingProx: state.loadingProx,
        loadingBus: state.loadingBus,
        loadingDet: state.loadingDet,
        loading: state.loading,
        paquetes: state.paquetes,
        paquete: state.paquete,
        getPaquetesInit,
        getPaquete,
        getPaquetes,
        getPaquetesBusqueda,
        getPaquetesDetail,
      }}
    >
      {props.children}
    </PaqueteContext.Provider>
  )
}

export default PaqueteState
