const Pagos = ({ compra }) => {
  const {
    reference_sale,
    state_pol,
    response_code_pol,
    created_on,
    value,
    description,
    payment_method_name,
    reference_pol,
  } = compra
  const { paquete } = reference_sale

  const verifyPol = () => {
    switch (response_code_pol) {
      case '1':
        return 'Transacción aprobada'
      case '4':
        return 'Transacción rechazada por entidad financiera'
      case '5':
        return 'Transacción rechazada por el banco'
      case '6':
        return 'Fondos insuficientes'
      case '7':
        return 'Tarjeta inválida'
      case '9':
        return 'Tarjeta vencida'
      case '14':
        return 'Transacción inválida'
      case '19':
        return 'Transacción abandonada por el pagador'
      case '20':
        return 'Transacción expirada'
      case '9998':
        return 'Transacción no permitida al tarjetahabiente'
      default:
        return 'No se pudo procesar el pago'
    }
  }

  const color = state_pol === '4' ? 'success' : 'danger'
  const msg = verifyPol()

  const fechaPago = new Date(created_on)
  return (
    <div className={`border border-${color} p-3 mb-3 rounded-lg shadow`}>
      <h4 className={`text-${color} float-right`}>{msg}</h4>
      <h2>
        <small>#</small>
        {reference_pol} <small>{description}</small>
      </h2>
      <div className="row">
        <div className="col-lg-2">
          <img
            src={paquete.foto.data.thumbnails[2].url}
            className="img-fluid rounded-pill"
            alt="Cover"
            loading="lazy"
          />
        </div>
        <div className="col-lg-10">
          <h4>
            Fecha de Pago:{' '}
            <small>
              {Intl.DateTimeFormat('es-ES', {
                dateStyle: 'long',
                timeStyle: 'medium',
              }).format(fechaPago)}
            </small>
          </h4>
          <h4>
            Medio de Pago: <small>{payment_method_name}</small>
          </h4>
          <h4>
            Costo Total:{' '}
            <small className={color === 'danger' ? 'text-danger' : ''}>
              ${Intl.NumberFormat('es-ES').format(value)}
            </small>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default Pagos
