import { HANDLE_CLEAN, HANDLE_ERROR, SEND_EMAIL } from '../../types'

const EmailReducer = (state, action) => {
  switch (action.type) {
    case SEND_EMAIL:
      return {
        ...state,
        msg: action.payload,
      }
    case HANDLE_ERROR:
      return {
        ...state,
        msg: action.payload,
      }
    case HANDLE_CLEAN:
      return {
        ...state,
        msg: { type: '', msg: '', status: false },
      }
    default:
      return {
        ...state,
      }
  }
}

export default EmailReducer
