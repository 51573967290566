import { useReducer } from 'react'
import AuthContext from './AuthContext'
import AuthReducer from './AuthReducer'
import clienteAxios from '../../config/clienteAxios'
import {
  HANDLE_CLEAN,
  HANDLE_ERROR,
  HANDLE_SUCCESS,
  USER_CHANGE,
  USER_GET,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REFRESH,
  USER_REGISTER,
  USER_REQUEST,
  USER_RESET,
} from '../../types'
import tokenAuth from '../../config/token'

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    autenticado: false,
    user: [],
    loading: true,
    msg: {
      status: false,
      type: '',
      msg: '',
    },
    success: false,
  }

  const [state, dispatch] = useReducer(AuthReducer, initialState)

  const register = async (data) => {
    try {
      delete data['confirmPassword']
      const res = await clienteAxios.post('users', data)
      dispatch({
        type: USER_REGISTER,
        payload: res.data.data,
      })
      const msg = {
        status: true,
        type: 'success',
        msg: 'Registrado correctamente, inicie sesion',
      }
      handleMessage(msg)
    } catch (e) {
      const msg = {
        status: true,
        type: 'error',
        msg: 'No se ha podido registrar',
      }
      handleMessage(msg)
    }
  }

  const login = async (data) => {
    try {
      const res = await clienteAxios.post('auth/authenticate', data)
      dispatch({
        type: USER_LOGIN,
        payload: res.data.data,
      })
      const token = localStorage.getItem('token')
      tokenAuth(token)
    } catch (e) {
      const msg = {
        status: true,
        type: 'error',
        msg: 'Usuario o contraseña incorrectos',
      }
      handleMessage(msg)
    }
  }

  const getUser = async () => {
    const token = localStorage.getItem('token')
    tokenAuth(token)
    try {
      const res = await clienteAxios.get('users/me')
      dispatch({
        type: USER_GET,
        payload: res.data.data,
      })
    } catch (e) {
      dispatch({
        type: USER_LOGOUT,
      })
    }
  }

  // Refresh token
  const refresh = async (data) => {
    try {
      const res = await clienteAxios.post('auth/refresh', data)
      dispatch({
        type: USER_REFRESH,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  // Change user data
  const changeUser = async (data) => {
    delete data['confirmPassword']
    try {
      const res = await clienteAxios.patch(`users/${data.id}`, data)
      dispatch({
        type: USER_CHANGE,
        payload: res.data.data,
      })
      const msg = {
        status: true,
        type: 'success',
        msg:
          'Se han actualizado los datos de usuario, cierre sesion e inicie nuevamente para aplicar los cambios',
      }
      handleMessage(msg)
    } catch (e) {
      const msg = {
        status: true,
        type: 'error',
        msg: 'No se ha podido actualizar los datos',
      }
      handleMessage(msg)
    }
  }

  // Obtener token para cambiar pass
  const requestPass = async (data) => {
    try {
      const res = await clienteAxios.post('auth/password/request', data)
      dispatch({
        type: USER_REQUEST,
        payload: res.data.data,
      })
      dispatch({
        type: HANDLE_SUCCESS,
        payload: true,
      })
    } catch (e) {
      const msg = {
        type: 'error',
        status: true,
        msg: 'No se ha podido enviar el codigo, verifique nuevamente',
      }
      handleMessage(msg)
    }
  }

  // Cambiar password
  const resetPass = async (data) => {
    delete data['confirmPassword']
    try {
      const res = await clienteAxios.post('auth/password/reset', data)
      dispatch({
        type: USER_RESET,
        payload: res.data.data,
      })
    } catch (e) {
      const msg = {
        type: 'error',
        status: true,
        msg: 'No se ha podido reestablecer la contraseña',
      }
      handleMessage(msg)
    }
  }

  const handleMessage = (msg) => {
    dispatch({
      type: HANDLE_ERROR,
      payload: msg,
    })

    setTimeout(() => {
      dispatch({
        type: HANDLE_CLEAN,
      })
    }, 5000)
  }

  const handleCleanSuccess = () => {
    dispatch({
      type: HANDLE_SUCCESS,
      payload: false,
    })
  }

  // Cerrar sesion
  const logout = () => {
    dispatch({
      type: USER_LOGOUT,
    })
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        autenticado: state.autenticado,
        loading: state.loading,
        msg: state.msg,
        success: state.success,
        register,
        login,
        getUser,
        refresh,
        changeUser,
        requestPass,
        resetPass,
        handleCleanSuccess,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthState
