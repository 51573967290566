import { useFormik } from 'formik'
import * as Yup from 'yup'
import Encabezado from './experiencia/Encabezado'
import mountains from '../assets/mountains.jpg'
import { Link } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import AuthContext from '../context/auth/AuthContext'
import FormDanger from '../components/FormDanger'
import Casco from '../components/Casco'

const Login = ({ history }) => {
  const authContext = useContext(AuthContext)
  const { login, autenticado, msg } = authContext

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Ingrese un email valido')
        .required('Este campo es Obligatorio'),
      password: Yup.string().required('Este campo es obligatorio'),
    }),
    onSubmit: (data) => {
      login(data)
    },
  })

  useEffect(() => {
    if (autenticado) history.push('/')
    // eslint-disable-next-line
  }, [autenticado])

  return (
    <>
      <Casco titulo="Inicia sesión con tu cuenta" />
      <Encabezado foto={mountains} nombre="Inicia sesión con tu cuenta" />
      <div className="container mt-3 mb-3">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 shadow-lg p-5 rounded">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Correo Electrónico:</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="elmercurio@mail.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormDanger msg={formik.errors.email} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password">Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="*********"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <FormDanger msg={formik.errors.password} />
                ) : null}
              </div>
              <div className="form-group text-center">
                <Link to="/forget" className="text-dark">
                  ¿Olvido su contraseña?
                </Link>
                <br />
                <button
                  type="submit"
                  className="btn btn-primary float-right btn-block mt-4 shadow"
                >
                  Iniciar Sesión
                </button>
                <Link to="/signup" className="text-dark">
                  ¿No tiene cuenta? Cree una.
                </Link>
              </div>
              {msg.status ? <FormDanger msg={msg.msg} /> : null}
            </form>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </>
  )
}

export default Login
