import {
  HANDLE_CLEAN,
  HANDLE_ERROR,
  HANDLE_SUCCESS,
  USER_CHANGE,
  USER_GET,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REGISTER,
  USER_RESET,
} from '../../types'

const AuthReducer = (state, action) => {
  switch (action.type) {
    case USER_REGISTER:
      return {
        ...state,
      }
    case USER_LOGIN:
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        autenticado: true,
        loading: false,
      }
    case USER_LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        user: [],
        autenticado: false,
        loading: false,
        token: localStorage.getItem('token'),
      }
    case USER_GET:
      return {
        ...state,
        user: action.payload,
        autenticado: true,
        loading: false,
      }
    case USER_CHANGE:
      return {
        ...state,
        user: action.payload,
        autenticado: true,
        loading: false,
      }
    case USER_RESET:
      return {
        ...state,
        success: true,
      }
    case HANDLE_ERROR:
      return {
        ...state,
        msg: action.payload,
      }
    case HANDLE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case HANDLE_CLEAN:
      return {
        ...state,
        msg: {
          status: false,
          type: '',
          msg: '',
        },
      }
    default:
      return {
        ...state,
      }
  }
}

export default AuthReducer
