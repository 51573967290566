import Encabezado from './experiencia/Encabezado'
import foto from '../assets/lake.jpg'
import { useContext, useEffect } from 'react'
import BlogContext from '../context/blog/BlogContext'
import Spinner from '../components/Spinner'
import Card from './blog/Card'
import Casco from '../components/Casco'

const Blog = () => {
  const blogContext = useContext(BlogContext)
  const { entradas, getEntradas, loading } = blogContext

  useEffect(() => {
    getEntradas()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Casco titulo="Blog" />
      <Encabezado nombre="Nuestro Blog" foto={foto} />
      <div className="home-blog-area section-padding2">
        <div className="container">
          <div className="row">
            {loading ? (
              <Spinner />
            ) : Object.keys(entradas).length > 0 ? (
              entradas.map((entrada) => (
                <Card key={entrada.id} entrada={entrada} />
              ))
            ) : (
              <h2>No hay Entradas</h2>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog
