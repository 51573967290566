import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PaqueteContext from '../context/paquete/PaqueteContext'
import Encabezado from './experiencia/Encabezado'
import Experiencias from './inicio/Experiencias'
import foto from '../assets/mountains.jpg'
import Casco from '../components/Casco'

const Busqueda = () => {
  // CONTEXT
  const paqueteContext = useContext(PaqueteContext)
  const { paquetesBus, loadingBus, getPaquetesBusqueda } = paqueteContext

  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    getPaquetesBusqueda(params.get('destino'), params.get('categoria'))
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Casco titulo="Búsqueda" />
      <Encabezado nombre="Búsqueda" foto={foto} />
      <Experiencias
        titulo="Experiencias"
        listado={paquetesBus}
        loading={loadingBus}
      />
    </>
  )
}

export default Busqueda
