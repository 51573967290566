import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useContext, useEffect, useState } from 'react'
import EmailContext from '../../context/email/EmailContext'
import FormDanger from '../../components/FormDanger'
import AuthContext from '../../context/auth/AuthContext'

const ContactosDetail = () => {
  const emailContext = useContext(EmailContext)
  const { msg, sendEmail } = emailContext

  const authContext = useContext(AuthContext)
  const { user } = authContext

  // eslint-disable-next-line
  const [autenticado, setAutenticado] = useState(Object.keys(user).length > 0)

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      nombre: autenticado ? user.first_name : '',
      email: autenticado ? user.email : '',
      telefono: autenticado ? user.last_name : '',
      mensaje: '',
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required('Este campo es obligatorio'),
      email: Yup.string()
        .email('Ingrese un email valido')
        .required('Este campo es Obligatorio'),
      telefono: Yup.string().required('Este campo es obligatorio'),
      mensaje: Yup.string().required('Este campo es obligatorio'),
    }),
    onSubmit: (data) => {
      sendEmail(data)
    },
  })

  useEffect(() => {
    if (msg.status) formik.resetForm()
    // eslint-disable-next-line
  }, [msg])

  return (
    <section className="contact-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="contact-title">Ponte en contacto con nosotros</h2>
          </div>
          <div className="col-lg-8">
            <form
              className="form-contact contact_form"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      className="form-control w-100"
                      name="mensaje"
                      id="mensaje"
                      cols="30"
                      rows="9"
                      placeholder="Ingresa tu consulta"
                      value={formik.values.mensaje}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                    {formik.touched.mensaje && formik.errors.mensaje ? (
                      <FormDanger msg={formik.errors.mensaje} />
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      className="form-control valid"
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Tu Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <FormDanger msg={formik.errors.email} />
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      className="form-control valid"
                      name="telefono"
                      id="telefono"
                      type="number"
                      placeholder="Tu Teléfono"
                      value={formik.values.telefono}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.telefono && formik.errors.telefono ? (
                      <FormDanger msg={formik.errors.telefono} />
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="nombre"
                      id="nombre"
                      type="text"
                      placeholder="Tu Nombre"
                      value={formik.values.nombre}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.nombre && formik.errors.nombre ? (
                      <FormDanger msg={formik.errors.nombre} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <button
                  type="submit"
                  className="button button-contactForm boxed-btn"
                >
                  Enviar Consulta
                </button>
              </div>
              {msg.status ? (
                <p className={`text-${msg.type}`}>{msg.msg}</p>
              ) : null}
            </form>
          </div>
          <div className="col-lg-3 offset-lg-1">
            <div className="media contact-info">
              <span className="contact-info__icon">
                <i className="ti-home"></i>
              </span>
              <div className="media-body">
                <h3>Aquitania, Boyacá.</h3>
                <p>Carrera 7 # 6-17</p>
              </div>
            </div>
            <div className="media contact-info">
              <span className="contact-info__icon">
                <i className="ti-tablet"></i>
              </span>
              <div className="media-body">
                <h3> 3204029551- 3125835557</h3>
              </div>
            </div>
            <div className="media contact-info">
              <span className="contact-info__icon">
                <i className="ti-email"></i>
              </span>
              <div className="media-body">
                <h3>soporte@mamapachaexperience.com</h3>
                <p>Envianos tu consulta!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactosDetail
