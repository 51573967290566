import { useContext, useEffect } from 'react'
import Spinner from '../../components/Spinner'
import AuthContext from '../../context/auth/AuthContext'
import MiplanContext from '../../context/miPlan/MiplanContext'
import Pagos from './Pagos'

const PagosContainer = () => {
  const miplanContext = useContext(MiplanContext)
  const { listado, loading, getPlan } = miplanContext

  const authContext = useContext(AuthContext)
  const { user } = authContext
  const { id } = user

  useEffect(() => {
    getPlan(id)
    // eslint-disable-next-line
  }, [id])
  return (
    <>
      {loading ? (
        <Spinner />
      ) : Object.keys(listado).length > 0 ? (
        listado.map((compra) => <Pagos key={compra.id} compra={compra} />)
      ) : (
        <h2>No hay Pagos</h2>
      )}
    </>
  )
}

export default PagosContainer
