const Quotes = ({ comentario, paquete }) => {
  return (
    <div className="col-lg-6">
      <div className="h1-testimonial-active">
        <div className="single-testimonial text-center">
          <div className="testimonial-caption ">
            <div className="testimonial-top-cap">
              <p>"{comentario}"</p>
            </div>
          </div>
          <div className="testimonial-caption ">
            <div className="testimonial-top-cap text-center">
              <p className="font-italic font-weight-light">
                Comentario en {paquete.nombre}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quotes
