import { md5 } from 'md5js'

const PlanCheckDetail = ({ plan }) => {
  const { id, owner, fecha, viajeros, total, paquete } = plan

  const {
    REACT_APP_MERCHANT,
    REACT_APP_KEY,
    REACT_APP_ACCOUNT,
    REACT_APP_PAYU,
  } = process.env

  // “ApiKey~merchantId~referenceCode~amount~currency”

  const signature = md5(
    `${REACT_APP_KEY}~${REACT_APP_MERCHANT}~${id}~${total}~COP`,
    32
  )

  return (
    <>
      <h1>Detalles del paquete:</h1>

      <hr />
      <h3>
        Nombre: <small>{paquete.nombre}</small>
      </h3>
      <h3>
        Tipo de Experiencia: <small>{paquete.categoria.nombre}</small>
      </h3>
      <h3>
        Destino:{' '}
        <small>{`${paquete.ciudad.nombre} - ${paquete.ciudad.departamento.nombre}`}</small>
      </h3>
      <h3>
        Fecha de Salida:{' '}
        <small>
          {Intl.DateTimeFormat('es-ES', {
            dateStyle: 'full',
            timeZone: 'GMT',
          }).format(new Date(fecha))}
        </small>
      </h3>
      <h3>
        Viajeros: <small>{viajeros}</small>
      </h3>
      <hr />
      <h3>
        Total: <small>${Intl.NumberFormat('es-ES').format(total)} pesos</small>
      </h3>
      <hr />
      <form method="post" action={REACT_APP_PAYU}>
        <input name="merchantId" type="hidden" value={REACT_APP_MERCHANT} />
        <input name="accountId" type="hidden" value={REACT_APP_ACCOUNT} />
        <input
          name="description"
          type="hidden"
          value={`Experiencia ${paquete.nombre} | Para: ${viajeros} Viajeros`}
        />
        <input name="referenceCode" type="hidden" value={id} />
        <input name="amount" type="hidden" value={total} />
        <input name="tax" type="hidden" value="0" />
        <input name="taxReturnBase" type="hidden" value="0" />
        <input name="currency" type="hidden" value="COP" />
        <input name="signature" type="hidden" value={signature} />
        <input name="buyerEmail" type="hidden" value={owner.email} />
        <input name="extra1" type="hidden" value={owner.id} />
        <input
          name="responseUrl"
          type="hidden"
          value="https://mamapachaexperience.com/misreservas"
        />
        <input
          name="confirmationUrl"
          type="hidden"
          value="https://www.mamapachaexperience.com/confirmation.php"
        />
        <input
          className="btn btn-primary btn-block"
          name="Submit"
          type="submit"
          value="Pagar"
        />
      </form>
    </>
  )
}

export default PlanCheckDetail
