import { GET_DESTINO, GET_DESTINOS, HANDLE_CLEAN } from '../../types'

const DestinoReducer = (state, action) => {
  switch (action.type) {
    case GET_DESTINOS:
      return {
        ...state,
        destinos: action.payload,
        loadingD: false,
      }
    case GET_DESTINO:
      return {
        ...state,
        destino: action.payload,
      }
    case HANDLE_CLEAN:
      return {
        ...state,
        destino: [],
        loadingD: true,
      }
    default:
      return {
        ...state,
      }
  }
}

export default DestinoReducer
