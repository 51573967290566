import {
  CREATE_COMMENT,
  GET_COMMENTARIO,
  GET_COMMENTS,
  GET_TESTIMONIOS,
  HANDLE_CLEAN,
} from '../../types'

const CommentReducer = (state, action) => {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        comentarios: action.payload,
        loadingC: false,
      }
    case GET_COMMENTARIO:
      return {
        ...state,
        comentario: action.payload,
      }
    case GET_TESTIMONIOS:
      return {
        ...state,
        testimonios: action.payload,
      }
    case CREATE_COMMENT:
      return {
        ...state,
        comentarios: [action.payload, ...state.comentarios],
      }
    case HANDLE_CLEAN:
      return {
        ...state,
        comentarios: [],
        loadingC: true,
      }
    default:
      return {
        ...state,
      }
  }
}

export default CommentReducer
