import Encabezado from './experiencia/Encabezado'
import PaqueteContext from '../context/paquete/PaqueteContext'
import { useContext, useEffect } from 'react'
import ReservaContainer from './reservar/ReservaContainer'
import logo from '../assets/logo.png'
import MiplanContext from '../context/miPlan/MiplanContext'
import Casco from '../components/Casco'

const Reservar = ({ history }) => {
  const paqueteContext = useContext(PaqueteContext)
  const { paquete } = paqueteContext

  const miplanContext = useContext(MiplanContext)
  const { plan } = miplanContext

  useEffect(() => {
    if (Object.keys(paquete).length === 0) {
      alert('Debe Escoger un Destino para Reservar')
      history.push('/')
    }
    // eslint-disable-next-line
  }, [])

  const { nombre, foto } = paquete

  useEffect(() => {
    if (Object.keys(plan).length > 0) history.push('/checkplan')
    // eslint-disable-next-line
  }, [plan])

  return (
    <>
      {Object.keys(paquete).length === 0 ? null : (
        <>
          <Casco titulo={`Reserva Ya: ${nombre}`} />
          <Encabezado
            foto={foto.data.full_url}
            nombre={`Reserva Ya: ${nombre}`}
          />
          <div className="container shadow-lg p-5">
            <div className="row">
              <div className="col-lg-4">
                <img
                  src={logo}
                  alt="Mamapacha Logo"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-8">
                <ReservaContainer paquete={paquete} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Reservar
