import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, NavLink } from 'react-router-dom'
import logo from '../assets/logo.png'
import AuthContext from '../context/auth/AuthContext'
import CategoriaContext from '../context/categoria/CategoriaContext'

const Navbar = () => {
  const categoriaContext = useContext(CategoriaContext)
  const { listado, loading, getCategorias } = categoriaContext

  const authContext = useContext(AuthContext)
  const { autenticado, user, token, getUser, logout } = authContext

  // STATE
  const [logoutS, setLogoutS] = useState(false)
  const [admin, setAdmin] = useState(false)

  useEffect(() => {
    getCategorias()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (user.role === (1 || 3)) {
      setAdmin(true)
    }
  }, [user])

  useEffect(() => {
    if (logoutS) {
      logout()
      window.location.reload()
    }
    // eslint-disable-next-line
  }, [logoutS])

  return (
    <>
      <header>
        <div className="header-area">
          <div className="main-header ">
            <div className="header-top top-bg d-none d-lg-block">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-8">
                    <div className="header-info-left">
                      <ul>
                        <li>soporte@mamapachaexperience.com</li>
                        <li>3204029551- 3125835557</li>
                        <li>Mamapacha Experience</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="header-info-right f-right">
                      <ul className="header-social">
                        <li>
                          <a
                            href="https://www.facebook.com/pages/category/Tour-Agency/MamaPacha-Experience-583410512076510/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/mamapachaexperience"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://wa.me/573204029551"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-whatsapp"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://wa.me/573125835557"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-whatsapp"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="header-bottom header-sticky">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-2 col-lg-2 col-md-1">
                    <div className="logo">
                      <NavLink to="/">
                        <img
                          src={logo}
                          alt="Logo Mamapacha"
                          className="nav-logo"
                          loading="lazy"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-10">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>

                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav mr-auto">
                          <li className="nav-item">
                            <Link className="nav-link" to="/">
                              INICIO
                            </Link>
                          </li>

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#!"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              CATEGORÍAS
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              {loading ? (
                                <Link className="dropdown-item" to="#">
                                  Cargando...
                                </Link>
                              ) : Object.keys(listado).length > 0 ? (
                                listado.map(({ id, nombre }) => (
                                  <NavLink
                                    key={id}
                                    className="dropdown-item"
                                    to={`/categoria/${id}`}
                                  >
                                    {nombre}
                                  </NavLink>
                                ))
                              ) : (
                                <Link className="dropdown-item" to="#">
                                  No hay Categorías
                                </Link>
                              )}
                            </div>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/categoria/1">
                              ALOJAMIENTOS
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/blog">
                              BLOG
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/nosotros">
                              NOSOTROS
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/contactos">
                              CONTÁCTENOS
                            </NavLink>
                          </li>
                          {autenticado && Object.keys(user).length > 0 ? (
                            <li className="nav-item dropdown">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#!"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {user.first_name.toUpperCase()}
                              </a>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <Link to="/usuario" className="dropdown-item">
                                  Mi Cuenta
                                </Link>
                                {admin ? (
                                  <Link
                                    to="/reservas"
                                    className="dropdown-item"
                                  >
                                    Admin Reservas
                                  </Link>
                                ) : null}
                                <Link
                                  to="/misreservas"
                                  className="dropdown-item"
                                >
                                  Mis Reservas
                                </Link>
                                <Link
                                  to="/historialpagos"
                                  className="dropdown-item"
                                >
                                  Historial de Pagos
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Link
                                  to="#!"
                                  onClick={() => setLogoutS(true)}
                                  className="dropdown-item"
                                >
                                  Cerrar Sesión
                                </Link>
                              </div>
                            </li>
                          ) : (
                            <li className="nav-item">
                              <NavLink className="nav-link" to="/login">
                                INICIAR SESIÓN
                              </NavLink>
                            </li>
                          )}
                          <li className="nav-item">
                            <div id="ytWidget"></div>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="col-12">
                    <div className="mobile_menu d-block d-lg-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Helmet>
        <script
          src="https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=es&widgetTheme=dark&autoMode=true"
          type="text/javascript"
        ></script>
      </Helmet>
    </>
  )
}

export default Navbar
