import { useReducer } from 'react'
import { GET_ENTRADA, GET_ENTRADAS, HANDLE_CLEAN } from '../../types'
import BlogContext from './BlogContext'
import BlogReducer from './BlogReducer'
import clienteAxios from '../../config/clienteAxios'

const BlogState = (props) => {
  const initialState = {
    loading: true,
    loadingE: true,
    entradas: [],
    entrada: [],
  }

  const [state, dispatch] = useReducer(BlogReducer, initialState)

  // Get Entradas
  const getEntradas = async () => {
    try {
      const res = await clienteAxios.get(
        'items/entrada?fields=*.*.*&limit=-1&sort=-created_on'
      )
      dispatch({
        type: GET_ENTRADAS,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  // Get Entrada
  const getEntrada = async (id) => {
    handleClean()
    try {
      const res = await clienteAxios.get(`items/entrada/${id}?fields=*.*.*`)
      dispatch({
        type: GET_ENTRADA,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  // Limpiar entradas
  const handleClean = () => {
    dispatch({
      type: HANDLE_CLEAN,
    })
  }

  return (
    <BlogContext.Provider
      value={{
        loading: state.loading,
        loadingE: state.loadingE,
        entradas: state.entradas,
        entrada: state.entrada,
        getEntradas,
        getEntrada,
      }}
    >
      {props.children}
    </BlogContext.Provider>
  )
}

export default BlogState
