import { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'
import Oferta from './Oferta'

const RightSection = ({ datos }) => {
  const {
    ciudad,
    nombre,
    descripcion_corta,
    costo,
    fecha,
    categoria,
    oferta,
    viajeros_rebaja,
    porcentaje_oferta,
  } = datos

  const authContext = useContext(AuthContext)
  const { autenticado } = authContext

  const precio = new Intl.NumberFormat('es-ES').format(costo)

  const salida = new Date(fecha)

  return (
    <>
      <div className="section-tittle section-tittle2">
        <span>
          {ciudad.nombre}, {ciudad.departamento.nombre} - {categoria.nombre}
        </span>
        <h2>{nombre}</h2>
        <h4>Reserva desde:</h4>
        {viajeros_rebaja ? '(Tarifa dinámica)' : null}
        {oferta ? (
          <Oferta porcentaje={porcentaje_oferta} costo={costo} />
        ) : (
          <h3>${precio} pesos</h3>
        )}
        <br />
        {fecha ? (
          <h4>
            Fecha de salida:{' '}
            {Intl.DateTimeFormat('es-ES', {
              dateStyle: 'full',
              timeZone: 'GMT',
            }).format(salida)}
          </h4>
        ) : null}
      </div>
      <div className="support-caption">
        <p>{descripcion_corta}</p>
        {autenticado ? (
          <Link to="/reservar" className="btn btn-primary shadow">
            Reservar
          </Link>
        ) : (
          <Link to="/login" className="btn btn-secondary shadow">
            Inicia Sesión Para Reservar
          </Link>
        )}
      </div>
    </>
  )
}

export default RightSection
