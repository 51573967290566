import aliado1 from '../../assets/aliado1.PNG'
import aliado2 from '../../assets/aliado2.jpeg'
import aliado3 from '../../assets/aliado3.png'

const Aliados = () => {
  return (
    <div className="our-services servic-padding">
      <div className="container">
        <div className="row d-flex justify-contnet-center">
          <div className="col-lg-12">
            <div className="section-tittle text-center">
              <h2>Con el respaldo de:</h2>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div className="single-services text-center mb-30">
              <div className="services-ion">
                <img
                  src={aliado1}
                  className="img-fluid"
                  alt="Aliado Boyacá es para vivirla"
                  loading="lazy"
                />
              </div>
              <div className="services-cap">
                <h5>Boyacá es para vivirla.</h5>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div className="single-services text-center mb-30">
              <div className="services-ion">
                <img
                  src={aliado2}
                  className="img-fluid"
                  alt="Aliado Boyaca es para vivirla"
                  loading="lazy"
                />
              </div>
              <div className="services-cap">
                <h5>Negocios Verdes Corpoboyacá.</h5>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div className="single-services text-center mb-30">
              <div className="services-ion">
                <img
                  src={aliado3}
                  className="img-fluid"
                  alt="Aliado Boyaca es para vivirla"
                  loading="lazy"
                />
              </div>
              <div className="services-cap">
                <h5>Boyacá turismo Sugamuxi.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aliados
