import { useReducer } from 'react'
import CommentContext from './CommentContext'
import CommentReducer from './CommentReducer'
import clienteAxios from '../../config/clienteAxios'
import {
  CREATE_COMMENT,
  GET_COMMENTARIO,
  GET_COMMENTS,
  GET_TESTIMONIOS,
  HANDLE_CLEAN,
} from '../../types'

const CommentState = (props) => {
  const initialState = {
    comentarios: [],
    comentario: [],
    testimonios: [],
    loadingC: [],
  }

  const [state, dispatch] = useReducer(CommentReducer, initialState)

  const getComentarios = async (id) => {
    handleClean()
    try {
      const res = await clienteAxios.get(
        `items/comentario?filter[paquete.id][eq]=${id}&sort=-created_on&fields=*.*.*.*`
      )
      dispatch({
        type: GET_COMMENTS,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getTestimonios = async () => {
    try {
      const res = await clienteAxios.get(
        `items/comentario?sort=-created_on&fields=*.*&limit=2`
      )
      dispatch({
        type: GET_TESTIMONIOS,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getComentario = async (owner, paquete) => {
    try {
      const res = await clienteAxios.get(
        `items/compra?filter[reference_sale.paquete.id][eq]=${paquete}&filter[reference_sale.owner.id][eq]=${owner}&fields=*.*.*.*`
      )
      dispatch({
        type: GET_COMMENTARIO,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const createComentario = async (data) => {
    try {
      const res = await clienteAxios.post(
        `items/comentario?fields=*.*.*.*`,
        data
      )
      dispatch({
        type: CREATE_COMMENT,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const handleClean = async () => {
    dispatch({
      type: HANDLE_CLEAN,
    })
  }

  return (
    <CommentContext.Provider
      value={{
        comentarios: state.comentarios,
        comentario: state.comentario,
        testimonios: state.testimonios,
        loadingC: state.loadingC,
        getComentarios,
        getComentario,
        getTestimonios,
        createComentario,
      }}
    >
      {props.children}
    </CommentContext.Provider>
  )
}

export default CommentState
