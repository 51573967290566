import { GET_CATEGORIA, GET_CATEGORIAS } from '../../types'

const CategoriaReducer = (state, action) => {
  switch (action.type) {
    case GET_CATEGORIAS:
      return {
        ...state,
        listado: action.payload,
        loading: false,
      }
    case GET_CATEGORIA:
      return {
        ...state,
        categoria: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default CategoriaReducer
