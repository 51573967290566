import { useReducer } from 'react'
import clienteAxios from '../../config/clienteAxios'
import { GET_CATEGORIAS, GET_CATEGORIA } from '../../types'
import CategoriaContext from './CategoriaContext'
import CategoriaReducer from './CategoriaReducer'

const CategoriaState = (props) => {
  const initialState = {
    listado: [],
    categoria: [],
    loading: true,
  }

  const [state, dispatch] = useReducer(CategoriaReducer, initialState)

  const getCategorias = async () => {
    try {
      const res = await clienteAxios.get(
        'items/categoria?filter[nombre][neq]=alojamiento'
      )
      dispatch({
        type: GET_CATEGORIAS,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getCategoria = async (id) => {
    try {
      const res = await clienteAxios.get(`items/categoria/${id}`)
      dispatch({
        type: GET_CATEGORIA,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  return (
    <CategoriaContext.Provider
      value={{
        listado: state.listado,
        categoria: state.categoria,
        loading: state.loading,
        getCategorias,
        getCategoria,
      }}
    >
      {props.children}
    </CategoriaContext.Provider>
  )
}

export default CategoriaState
