import {
  GET_PAQUETE,
  GET_PAQUETES,
  GET_PAQUETES_ALO,
  GET_PAQUETES_BOY,
  GET_PAQUETES_BUSQ,
  GET_PAQUETES_COL,
  GET_PAQUETES_DETAIL,
  GET_PAQUETES_PROX,
  HANDLE_CLEAN,
} from '../../types'

const PaqueteReducer = (state, action) => {
  switch (action.type) {
    case GET_PAQUETES_PROX:
      return {
        ...state,
        paquetesProx: action.payload,
        loadingProx: false,
      }
    case GET_PAQUETES_BOY:
      return {
        ...state,
        paquetesBoy: action.payload,
        loadingBoy: false,
      }
    case GET_PAQUETES_COL:
      return {
        ...state,
        paquetesCol: action.payload,
        loadingCol: false,
      }
    case GET_PAQUETES_ALO:
      return {
        ...state,
        paquetesAlo: action.payload,
        loadingAlo: false,
      }
    case GET_PAQUETES_BUSQ:
      return {
        ...state,
        paquetesBus: action.payload,
        loadingBus: false,
      }
    case GET_PAQUETES_DETAIL:
      return {
        ...state,
        paquetesDet: action.payload,
        loadingDet: false,
      }
    case GET_PAQUETES:
      return {
        ...state,
        paquetes: action.payload,
        loading: false,
      }
    case GET_PAQUETE:
      return {
        ...state,
        paquete: action.payload,
        loading: false,
      }
    case HANDLE_CLEAN:
      return {
        ...state,
        loading: true,
        loadingBus: true,
        loadingDet: true,
      }
    default:
      break
  }
}

export default PaqueteReducer
