import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CategoriaContext from '../../context/categoria/CategoriaContext'
import DestinoContext from '../../context/destino/DestinoContext'
import lagoTota from '../../assets/portada/lagoTota.jpg'
import lagoTota2 from '../../assets/portada/lagoTota2.jpg'
import lagunaSierra from '../../assets/portada/lagunaSierra.jpg'
import oceta from '../../assets/portada/oceta.jpg'
import paramoSiscunsi from '../../assets/portada/paramoSiscunsi.jpg'

const Header = () => {
  // Context
  const categoriaContext = useContext(CategoriaContext)
  const { listado, loading } = categoriaContext

  const destinoContext = useContext(DestinoContext)
  const { destinos, loadingD, getDestinos } = destinoContext

  // state
  const [datos, setDatos] = useState({
    departamento: '',
    categoria: '',
  })

  const fotos = [lagoTota, lagoTota2, lagunaSierra, oceta, paramoSiscunsi]

  const [contador, setContador] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (contador === 4) {
        setContador(0)
      } else {
        setContador(contador + 1)
      }
    }, 5000)
  }, [contador])

  const handleChange = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    getDestinos()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="slider-area ">
      <div className="slider-active">
        <div
          className="single-slider hero-overly  slider-height d-flex align-items-center"
          style={{
            background: `rgba(0, 0, 0, 0.9)`,
            backgroundImage: `url(${fotos[contador]})`,
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            transition: '1s',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-9">
                <div className="hero__caption">
                  <h1>
                    Nuestras <span>Experiencias</span>
                  </h1>
                  <p>A donde te gustaría ir?</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <form className="search-box">
                  <div className="input-form mb-30 mt-10">
                    <select
                      name="departamento"
                      className="form-control"
                      id="departamento"
                      required
                      value={datos.departamento}
                      onChange={handleChange}
                    >
                      <option disabled value="">
                        Selecciona tu Destino
                      </option>
                      {loadingD ? (
                        <option disabled value="">
                          Cargando...
                        </option>
                      ) : Object.keys(destinos).length > 0 ? (
                        destinos.map(({ id, nombre }) => (
                          <option key={id} value={id}>
                            {nombre}
                          </option>
                        ))
                      ) : (
                        <option disabled value="">
                          No hay destinos
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="select-form mb-30 mt-10">
                    <div className="select-itms">
                      <select
                        required
                        name="categoria"
                        className="form-control"
                        id="categoria"
                        value={datos.categoria}
                        onChange={handleChange}
                      >
                        <option disabled value="">
                          Tipo de Experiencia
                        </option>
                        {loading ? (
                          <option disabled value="">
                            Cargando...
                          </option>
                        ) : Object.keys(listado).length > 0 ? (
                          listado.map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}s
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            No hay Experiencias
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="search-form mb-30">
                    {datos.categoria.length > 0 &&
                    datos.departamento.length > 0 ? (
                      <Link
                        to={`/busqueda?destino=${datos.departamento}&categoria=${datos.categoria}`}
                        className="btn btn-primary"
                      >
                        Buscar
                      </Link>
                    ) : (
                      <Link to="/" className="btn btn-primary disabled">
                        Seleccione
                      </Link>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
