import Encabezado from './experiencia/Encabezado'
import mountains from '../assets/mountains.jpg'
import { Link } from 'react-router-dom'
import FormDanger from '../components/FormDanger'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useContext } from 'react'
import AuthContext from '../context/auth/AuthContext'
import Casco from '../components/Casco'

const Forget = () => {
  // CONTEXT
  const authContext = useContext(AuthContext)
  const { success, requestPass, msg } = authContext

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      email: '',
      reset_url: 'https://mamapachaexperience.com/reset',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Ingrese un email valido')
        .required('Este campo es Obligatorio'),
    }),
    onSubmit: (data) => {
      requestPass(data)
      formik.resetForm()
    },
  })
  return (
    <>
      <Casco titulo="Olvide mi Contraseña" />
      <Encabezado foto={mountains} nombre="Olvide mi Contraseña" />
      <div className="container mt-3 mb-3">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 shadow-lg p-5 rounded">
            {!success ? (
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Correo Electrónico:</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="elmercurio@mail.com"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormDanger msg={formik.errors.email} />
                  ) : null}
                </div>
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn btn-primary float-right btn-block mt-4 shadow"
                  >
                    Enviar Código
                  </button>
                  <Link to="/login" className="text-dark">
                    O Inicie Sesión.
                  </Link>
                </div>
                {msg.status ? (
                  <p className={`text-${msg.type}`}>{msg.msg}</p>
                ) : null}
              </form>
            ) : (
              <h1>Se ha enviado el código a tu correo</h1>
            )}
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </>
  )
}

export default Forget
