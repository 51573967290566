import Encabezado from './experiencia/Encabezado'
import mountains from '../assets/mountains.jpg'
import AdminReservasContainer from './adminReservas/AdminReservasContainer'
import { useContext, useEffect } from 'react'
import AuthContext from '../context/auth/AuthContext'
import Casco from '../components/Casco'

const AdminReservas = ({ history }) => {
  const authContext = useContext(AuthContext)
  const { user } = authContext

  useEffect(() => {
    if (user.role !== 1 && user.role !== 3) history.push('/')
    // eslint-disable-next-line
  }, [history])

  return (
    <>
      <Casco titulo="Administra Tus Reservas" />
      <Encabezado foto={mountains} nombre={`Administrar Reservas`} />
      <div className="container shadow-lg p-5">
        <div className="row">
          <div className="col-lg-12 p-3">
            <AdminReservasContainer />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminReservas
