import Encabezado from './experiencia/Encabezado'
import MisReservasContainer from './misreservas/MisReservasContainer'
import mountains from '../assets/mountains.jpg'
import Casco from '../components/Casco'

const MisReservas = () => {
  return (
    <>
      <Casco titulo="Mis Reservas" />
      <Encabezado foto={mountains} nombre={`Mis Reservas`} />
      <div className="container shadow-lg p-5">
        <div className="row">
          <div className="col-lg-12 p-3">
            <MisReservasContainer />
          </div>
        </div>
      </div>
    </>
  )
}

export default MisReservas
