const Comentario = ({ datos }) => {
  const { owner, comentario } = datos
  return (
    <div className="shadow p-3 mb-3 rounded-lg">
      <h4>
        {owner.first_name} <small>Dice:</small>
      </h4>
      <p>{comentario}</p>
    </div>
  )
}

export default Comentario
