import { useReducer } from 'react'
import DestinoContext from './DestinoContext'
import DestinoReducer from './DestinoReducer'
import clienteAxios from '../../config/clienteAxios'
import { GET_DESTINO, GET_DESTINOS, HANDLE_CLEAN } from '../../types'

const DestinoState = (props) => {
  const initialState = {
    destinos: [],
    destino: [],
    loadingD: true,
  }

  const [state, dispatch] = useReducer(DestinoReducer, initialState)

  // Get Destinos
  const getDestinos = async () => {
    try {
      const res = await clienteAxios.get('items/departamento')
      dispatch({
        type: GET_DESTINOS,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  // Get Destino
  const getDestino = async (id) => {
    handleClean()
    try {
      const res = await clienteAxios.get(`items/departamento/${id}`)
      dispatch({
        type: GET_DESTINO,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const handleClean = () => {
    dispatch({
      type: HANDLE_CLEAN,
    })
  }

  return (
    <DestinoContext.Provider
      value={{
        destinos: state.destinos,
        destino: state.destino,
        loadingD: state.loadingD,
        getDestinos,
        getDestino,
      }}
    >
      {props.children}
    </DestinoContext.Provider>
  )
}

export default DestinoState
