const AdminItems = ({ compra }) => {
  const {
    reference_sale,
    state_pol,
    reference_pol,
    value,
    description,
  } = compra
  const { paquete, owner } = reference_sale

  const color = state_pol === '4' ? 'success' : 'danger'

  const fechaSalida = new Date(reference_sale.fecha)
  const currentDate = new Date()

  return (
    <div
      className={`border border-${color} p-3 mb-3 rounded-lg shadow reserva`}
    >
      <h2 className="float-right">
        <small>#{reference_pol}</small>
      </h2>
      <h2>{description}</h2>
      <div className="row">
        <div className="col-lg-2">
          <img
            src={paquete.foto.data.thumbnails[2].url}
            className="img-fluid rounded-pill"
            alt="Cover"
            loading="lazy"
          />
        </div>
        <div className="col-lg-5">
          <h4>
            Costo Total:{' '}
            <small>${Intl.NumberFormat('es-ES').format(value)}</small>
          </h4>
          <h4>
            Numero de Viajeros: <small>{reference_sale.viajeros}</small>
          </h4>
          <h4>
            Fecha de Salida:{' '}
            <small>
              {Intl.DateTimeFormat('es-ES', { dateStyle: 'full' }).format(
                fechaSalida
              )}
            </small>
          </h4>
          <h4>
            Estado:{' '}
            <small>
              {currentDate > fechaSalida ? 'Completada' : 'Por Completar'}
            </small>
          </h4>
        </div>
        <div className="col-lg-5">
          <h4>
            Nombres: <small>{owner.first_name}</small>
          </h4>
          <h4>
            Teléfono: <small>{owner.last_name}</small>
          </h4>
          <h4>
            Email: <small>{owner.email}</small>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default AdminItems
