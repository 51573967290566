import { useContext, useEffect } from 'react'
import Spinner from '../../components/Spinner'
import AuthContext from '../../context/auth/AuthContext'
import MiplanContext from '../../context/miPlan/MiplanContext'
import Items from './Items'

const MisReservasContainer = () => {
  const miplanContext = useContext(MiplanContext)
  const { reserva, loadingR, getReserva } = miplanContext

  const authContext = useContext(AuthContext)
  const { user } = authContext
  const { id } = user

  useEffect(() => {
    getReserva(id)
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      {loadingR ? (
        <Spinner />
      ) : Object.keys(reserva).length > 0 ? (
        reserva.map((compra) => <Items key={compra.id} compra={compra} />)
      ) : (
        <h2>No hay Reservas</h2>
      )}
    </>
  )
}

export default MisReservasContainer
