import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Spinner from '../components/Spinner'
import MiplanContext from '../context/miPlan/MiplanContext'
import PaqueteContext from '../context/paquete/PaqueteContext'
import Detalle from './experiencia/Detalle'

const Experiencia = () => {
  const paqueteContext = useContext(PaqueteContext)
  const { getPaquete, paquete, loading } = paqueteContext

  const miplanContext = useContext(MiplanContext)
  const { cleanPlan } = miplanContext

  const { id } = useParams()

  useEffect(() => {
    getPaquete(id)
    window.scrollTo(0, 0)
    cleanPlan()
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      {loading ? (
        <Spinner />
      ) : Object.keys(paquete).length > 0 ? (
        <Detalle paquete={paquete} />
      ) : null}
    </>
  )
}

export default Experiencia
