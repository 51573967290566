import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'

const NosotrosContent = () => {
  return (
    <>
      <div className="col-lg-12 text-center">
        <h1>MAMAPACHA EXPERIENCE</h1>
        <h3>¡Colecciona experiencias no cosas!</h3>
      </div>
      <div className="col-lg-8 my-auto">
        <p>
          Somos una plataforma digital de viajes que nace con el propósito de
          crear experiencias de turismo que promueven estilos de viaje más
          responsables tanto con las comunidades locales como con la naturaleza.
        </p>
        <p>
          En cada una de nuestras experiencias, trabajamos de la mano con
          comunidades y operadores locales con el propósito de conectar a las
          personas con la esencia de los lugares que visitamos. Tenemos
          experiencias todo incluido: aprendizaje, conciencia ambiental,
          paisajes naturales, tradiciones, nuevos amigos y mucho más.
        </p>
      </div>
      <div className="col-lg-4">
        <img src={logo} className="img-fluid" alt="Logo Mamapacha" />
      </div>
      <div className="col-lg-12">
        <Link to="/contactos" className="btn btn-primary">
          Contáctenos&raquo;
        </Link>
      </div>
    </>
  )
}

export default NosotrosContent
