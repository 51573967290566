import { GET_ENTRADA, GET_ENTRADAS, HANDLE_CLEAN } from '../../types'

const BlogReducer = (state, action) => {
  switch (action.type) {
    case GET_ENTRADAS:
      return {
        ...state,
        entradas: action.payload,
        loading: false,
      }

    case GET_ENTRADA:
      return {
        ...state,
        entrada: action.payload,
        loadingE: false,
      }
    case HANDLE_CLEAN:
      return {
        ...state,
        entrada: [],
        loadingE: true,
      }
    default:
      return {
        ...state,
      }
  }
}

export default BlogReducer
