import { useContext, useEffect } from 'react'
import CommentContext from '../context/comments/CommentContext'
import Comentario from './Comentario'
import Spinner from './Spinner'

const Comentarios = ({ paquete }) => {
  const commentContext = useContext(CommentContext)
  const { comentarios, loadingC, getComentarios } = commentContext

  useEffect(() => {
    getComentarios(paquete)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <h2>Comentarios</h2>
      {loadingC ? (
        <Spinner />
      ) : Object.keys(comentarios).length > 0 ? (
        comentarios.map((comentario) => (
          <Comentario key={comentario.id} datos={comentario} />
        ))
      ) : (
        <p>Aun no hay comentarios. Se el primero en comentar.</p>
      )}
    </>
  )
}

export default Comentarios
