import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import Spinner from '../../components/Spinner'

const Experiencias = ({
  titulo = '',
  listado,
  loading,
  clase = 'bg-light',
  link = false,
}) => {
  return (
    <div className={`favourite-place place-padding ${clase}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-tittle text-center">
              <h2>{titulo}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <Spinner />
          ) : Object.keys(listado).length > 0 ? (
            listado.map((lista) => <Card key={lista.id} datos={lista} />)
          ) : (
            <h2>Ups! Parece que no hay {titulo}</h2>
          )}
        </div>
        {link ? (
          <Link to={`/detalle/${link}`} className="btn btn-primary float-right">
            Ver Más&raquo;
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default Experiencias
