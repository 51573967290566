import { useContext, useEffect } from 'react'
import MiplanContext from '../context/miPlan/MiplanContext'
import Encabezado from './experiencia/Encabezado'
import logo from '../assets/logo.png'
import PlanCheckDetail from './checkPlan/PlanCheckDetail'
import Casco from '../components/Casco'

const CheckPlan = ({ history }) => {
  const miplanContext = useContext(MiplanContext)
  const { plan } = miplanContext

  const { paquete } = plan

  useEffect(() => {
    if (Object.keys(plan).length === 0) {
      alert('Debe seleccionar un plan')
      history.push('/')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {Object.keys(plan).length > 0 ? (
        <>
          <Casco titulo="Detalle de tu pedido" />
          <Encabezado
            foto={paquete.foto.data.full_url}
            nombre="Detalle de tu pedido"
          />
          <div className="container shadow-lg p-5">
            <div className="row">
              <div className="col-lg-4">
                <img
                  src={logo}
                  alt="Mamapacha Logo"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-8">
                <PlanCheckDetail plan={plan} />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default CheckPlan
