import { Helmet } from 'react-helmet'

const Casco = ({ titulo }) => {
  let titulino = titulo
    ? `${titulo} | MAMAPACHA EXPERIENCE`
    : 'MAMAPACHA EXPERIENCE'

  return (
    <Helmet>
      <title>{titulino}</title>
    </Helmet>
  )
}

export default Casco
