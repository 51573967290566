import Encabezado from './experiencia/Encabezado'
import mountains from '../assets/mountains.jpg'
import ContactosDetail from './contactos/ContactosDetail'
import { useEffect } from 'react'
import Casco from '../components/Casco'

const Contactos = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Casco titulo="Contáctenos" />
      <Encabezado foto={mountains} nombre="Contáctenos" />
      <ContactosDetail />
    </>
  )
}

export default Contactos
