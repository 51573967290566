import Encabezado from './experiencia/Encabezado'
import foto from '../assets/lake.jpg'
import FormDanger from '../components/FormDanger'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../context/auth/AuthContext'
import { useContext, useEffect } from 'react'
import Casco from '../components/Casco'

const Usuario = ({ history }) => {
  const authContext = useContext(AuthContext)
  const { user, changeUser, msg } = authContext

  useEffect(() => {
    if (Object.keys(user).length === 0) history.push('/')
    // eslint-disable-next-line
  }, [user])

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('Este campo es Obligatorio'),
      last_name: Yup.number()
        .positive('Ingrese un numero valido')
        .integer('Ingrese un numero valido')
        .required('Este campo es Obligatorio'),
      password: Yup.string().oneOf(
        [Yup.ref('confirmPassword')],
        'Las contraseñas no coinciden'
      ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password')],
        'Las contraseñas no coinciden'
      ),
    }),
    onSubmit: (datos) => {
      changeUser(datos)
    },
  })

  return (
    <>
      <Casco titulo="Actualiza tu Usuario" />
      <Encabezado foto={foto} nombre={`Actualiza tu Usuario`} />
      <div className="container p-5">
        <div className="row">
          <div className="col-lg-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="first_name">Nombres y Apellidos:</label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  id="first_name"
                  placeholder="Elmer Curio"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <FormDanger msg={formik.errors.first_name} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Telefono:</label>
                <input
                  type="number"
                  className="form-control"
                  name="last_name"
                  id="last_name"
                  placeholder="319 294 2835"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <FormDanger msg={formik.errors.last_name} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password">Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="*********"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <FormDanger msg={formik.errors.password} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirmar Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="*********"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <FormDanger msg={formik.errors.confirmPassword} />
                ) : null}
              </div>

              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn btn-primary float-right btn-block mt-4"
                >
                  Actualizar mi Cuenta
                </button>
              </div>
              {msg.status ? (
                <p className={`text-${msg.type}`}>{msg.msg}</p>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Usuario
