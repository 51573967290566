import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import AdminReservas from './routes/AdminReservas'
import Blog from './routes/Blog'
import Entrada from './routes/blog/Entrada'
import Busqueda from './routes/Busqueda'
import Categoria from './routes/Categoria'
import CheckPlan from './routes/CheckPlan'
import Contactos from './routes/Contactos'
import Detalle from './routes/Detalle'
import Error404 from './routes/Error404'
import Experiencia from './routes/Experiencia'
import Forget from './routes/Forget'
import Inicio from './routes/Inicio'
import Login from './routes/Login'
import MisReservas from './routes/MisReservas'
import Nosotros from './routes/Nosotros'
import PagosHist from './routes/PagosHist'
import PrivateRoute from './routes/PrivateRoute'
import Reservar from './routes/Reservar'
import ResetPass from './routes/ResetPass'
import Signup from './routes/Signup'
import Usuario from './routes/Usuario'

const Routes = () => {
  return (
    <Router>
      <Navbar />

      <Switch>
        <Route exact path="/experiencia/:id" component={Experiencia} />
        <Route exact path="/categoria/:id" component={Categoria} />
        <PrivateRoute exact path="/historialpagos" component={PagosHist} />
        <PrivateRoute exact path="/reservas" component={AdminReservas} />
        <PrivateRoute exact path="/misreservas" component={MisReservas} />
        <PrivateRoute exact path="/reservar" component={Reservar} />
        <PrivateRoute exact path="/checkplan" component={CheckPlan} />
        <Route exact path="/detalle/:id" component={Detalle} />
        <Route exact path="/blog/:id" component={Entrada} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/reset" component={ResetPass} />
        <Route exact path="/usuario" component={Usuario} />
        <Route exact path="/contactos" component={Contactos} />
        <Route exact path="/nosotros" component={Nosotros} />
        <Route exact path="/busqueda" component={Busqueda} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/forget" component={Forget} />
        <Route exact path="/" component={Inicio} />
        <Route path="*" component={Error404} />
      </Switch>

      <Footer />
    </Router>
  )
}

export default Routes
