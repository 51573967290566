import { useFormik } from 'formik'
import { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import CommentContext from '../context/comments/CommentContext'
import FormDanger from './FormDanger'

const CommentForm = ({ owner, paquete }) => {
  const commentContext = useContext(CommentContext)
  const { comentario, getComentario, createComentario } = commentContext

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      comentario: '',
      paquete: paquete,
    },
    validationSchema: Yup.object({
      comentario: Yup.string().required('Este campo es Obligatorio'),
    }),
    onSubmit: (data) => {
      createComentario(data)
      formik.resetForm()
    },
  })

  useEffect(() => {
    getComentario(owner, paquete)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {Object.keys(comentario).length > 0 ? (
        <>
          <h2>Dejanos tu Comentario</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <textarea
                type="text"
                className="form-control"
                name="comentario"
                id="comentario"
                placeholder="Dejanos tu comentario"
                value={formik.values.comentario}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.comentario && formik.errors.comentario ? (
                <FormDanger msg={formik.errors.comentario} />
              ) : null}
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary shadow">
                Comentar
              </button>
            </div>
          </form>
        </>
      ) : (
        <h2 className="font-italic">Reserva el Paquete para comentar</h2>
      )}
    </>
  )
}

export default CommentForm
