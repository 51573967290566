import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Casco from '../../components/Casco'
import Spinner from '../../components/Spinner'
import BlogContext from '../../context/blog/BlogContext'
import Encabezado from '../experiencia/Encabezado'

const Entrada = () => {
  const blogContext = useContext(BlogContext)
  const { loadingE, entrada, getEntrada } = blogContext

  const { id } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0)

    getEntrada(id)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {loadingE ? (
        <Spinner />
      ) : Object.keys(entrada).length > 0 ? (
        <>
          <Casco titulo={entrada.titulo} />
          <Encabezado
            foto={entrada.imagen.data.full_url}
            nombre={entrada.titulo}
          />
          <section className="blog_area single-post-area mt-2 mb-2">
            <div className="container shadow">
              <div className="row">
                <div className="col-lg-12 posts-list">
                  <div className="single-post">
                    <div className="feature-img">
                      <img
                        className="full-img"
                        src={entrada.imagen.data.full_url}
                        alt={entrada.titulo}
                        loading="lazy"
                      />
                    </div>
                    <div className="blog_details">
                      <h2>{entrada.descripcion}</h2>
                      <ul className="blog-info-link mt-3 mb-4">
                        <li>
                          <i className="fa fa-user"></i>{' '}
                          {entrada.owner.first_name}
                        </li>
                      </ul>
                      <hr />
                      <div
                        dangerouslySetInnerHTML={{ __html: entrada.contenido }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  )
}

export default Entrada
