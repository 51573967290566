import React from 'react'
import AuthState from './context/auth/AuthState'
import BlogState from './context/blog/BlogState'
import CategoriaState from './context/categoria/CategoriaState'
import CommentState from './context/comments/CommentState'
import DestinoState from './context/destino/DestinoState'
import EmailState from './context/email/EmailState'
import MiplanState from './context/miPlan/MiplanState'
import PaqueteState from './context/paquete/PaqueteState'
import Routes from './Routes'

function App() {
  return (
    <CategoriaState>
      <PaqueteState>
        <AuthState>
          <DestinoState>
            <MiplanState>
              <CommentState>
                <EmailState>
                  <BlogState>
                    <Routes />
                  </BlogState>
                </EmailState>
              </CommentState>
            </MiplanState>
          </DestinoState>
        </AuthState>
      </PaqueteState>
    </CategoriaState>
  )
}

export default App
