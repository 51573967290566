const Encabezado = ({ nombre = 'Experiencias', foto = '' }) => {
  return (
    <div className="slider-area ">
      <div
        className="single-slider slider-height2 d-flex align-items-center norepeat"
        style={{
          background: `linear-gradient(
            rgba(0, 0, 0, 0.4), 
            rgba(0, 0, 0, 0.4)
          ), url(${foto})`,
          backgroundPositionY: 'center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="hero-cap text-center">
                <h2>{nombre}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Encabezado
