import { useContext, useEffect } from 'react'
import foto from '../../assets/testimonios.jpg'
import Quotes from '../../components/Quotes'
import CommentContext from '../../context/comments/CommentContext'

const Testimonios = () => {
  const commentContext = useContext(CommentContext)
  const { testimonios, getTestimonios } = commentContext

  useEffect(() => {
    getTestimonios()
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className="testimonial-area testimonial-padding"
      style={{
        background: `linear-gradient(
          rgba(255, 255, 255, 0.1), 
          rgba(255, 255, 255, 0.1)
        ), url(${foto})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
    >
      <div className="container ">
        <div className="row d-flex justify-content-center">
          {Object.keys(testimonios).length > 0
            ? testimonios.map(({ id, comentario, paquete }) => (
                <Quotes key={id} comentario={comentario} paquete={paquete} />
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

export default Testimonios
