import { Link } from 'react-router-dom'
import mountain from '../assets/mountains.jpg'
import logo from '../assets/logo.png'

const Footer = () => {
  return (
    <footer>
      <div
        className="footer-area footer-padding footer-bg"
        style={{
          background: `linear-gradient(
            rgba(0, 0, 0, 0.8), 
            rgba(0, 0, 0, 0.8)
          ), url(${mountain})`,
        }}
      >
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-6">
              <div className="single-footer-caption mb-50">
                <div className="single-footer-caption mb-30">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src={logo} className="img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="footer-tittle">
                    <div className="footer-pera">
                      <p>
                        Somos una plataforma digital de viajes que nace con el
                        propósito de crear experiencias de turismo que promueven
                        estilos de viaje más responsables tanto con las
                        comunidades locales como con la naturaleza.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-5">
              <div className="single-footer-caption mb-50">
                <div className="footer-tittle">
                  <h4>Enlaces</h4>
                  <ul>
                    <li>
                      <Link to="/">Inicio</Link>
                    </li>
                    <li>
                      <Link to="/categoria/1">Alojamientos</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-7">
              <div className="single-footer-caption mb-50">
                <div className="footer-tittle">
                  <h4>Soporte</h4>
                  <ul>
                    <li>
                      <Link to="/nosotros">Nosotros</Link>
                    </li>
                    <li>
                      <Link to="/contactos">Contáctenos</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-padding">
            <div className="col-xl-7 col-lg-7 col-md-7">
              <div className="footer-copy-right">
                <p>
                  Copyright ©{new Date().getFullYear()} | Todos los derechos
                  reservados{' '}
                  <a href="https://mamapachaexperience.com">
                    Mamapacha Experience
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
