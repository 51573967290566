import { useFormik } from 'formik'
import { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import AuthContext from '../context/auth/AuthContext'
import mountains from '../assets/mountains.jpg'
import Encabezado from './experiencia/Encabezado'
import FormDanger from '../components/FormDanger'
import { useLocation } from 'react-router-dom'
import Casco from '../components/Casco'

const ResetPass = ({ history }) => {
  // Context Auth
  const authContext = useContext(AuthContext)
  const { success, msg, handleCleanSuccess, resetPass } = authContext

  const params = new URLSearchParams(useLocation().search)
  const token = params.get('token')

  useEffect(() => {
    handleCleanSuccess()
    if (!token) history.push('/')
    // eslint-disable-next-line
  }, [])

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      token: token,
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Este campo es obligatorio')
        .oneOf([Yup.ref('confirmPassword')], 'Las contraseñas no coinciden'),
      confirmPassword: Yup.string()
        .required('Este campo es obligatorio')
        .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden'),
    }),
    onSubmit: (data) => {
      resetPass(data)
      formik.resetForm()
    },
  })

  useEffect(() => {
    if (success) history.push('/login')
    // eslint-disable-next-line
  }, [success])

  return (
    <>
      <Casco titulo="Olvide Mi Contraseña" />
      <Encabezado foto={mountains} nombre="Olvide Mi Contraseña" />
      <div className="container mt-3 mb-3">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 shadow-lg p-5 rounded">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="*********"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <FormDanger msg={formik.errors.password} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirmar Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="*********"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <FormDanger msg={formik.errors.confirmPassword} />
                ) : null}
              </div>
              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn btn-primary float-right btn-block mt-4"
                >
                  Restablecer Contraseña
                </button>
              </div>
              {msg.status ? <FormDanger msg={msg.msg} /> : null}
            </form>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </>
  )
}

export default ResetPass
