import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import short from 'short-uuid'
import FormDanger from '../../components/FormDanger'
import MiplanContext from '../../context/miPlan/MiplanContext'

const ReservaContainer = ({ paquete }) => {
  const {
    id,
    nombre,
    categoria,
    costo,
    oferta,
    porcentaje_oferta,
    viajeros_rebaja,
    viajeros_porcentaje,
    cupo_min,
    cupo_max,
    ciudad,
    fecha,
  } = paquete

  // Context
  const miplanContext = useContext(MiplanContext)
  const { reservasPlan, createPlan, getReservasPlan } = miplanContext

  // STATE
  // Numero total de reservas desde la db
  const [totalReservas, setTotalReservas] = useState(0)
  // Numero de cupos incluyendo form
  const [cupo, setCupo] = useState(0)

  // Precio base del paquete
  const [base, setBase] = useState(() => {
    if (oferta) {
      const ofertaR = porcentaje_oferta / 100
      const rebaja = costo * ofertaR

      return costo - rebaja
    } else {
      return costo
    }
  })

  // EFFECT
  // Obtiene reservas y verifica si existe oferta
  useEffect(() => {
    getReservasPlan(id, fecha)

    if (oferta) {
      const ofertaR = porcentaje_oferta / 100
      const rebaja = costo * ofertaR

      setBase(costo - rebaja)
    }
    // eslint-disable-next-line
  }, [])

  // Obtiene el tottal de reservas desde la db
  useEffect(() => {
    setTotalReservas(() => {
      if (Object.keys(reservasPlan).length > 0) {
        let contador = 0
        reservasPlan.map(
          ({ reference_sale }) => (contador += reference_sale.viajeros)
        )
        return contador
      } else {
        return 0
      }
    })
  }, [reservasPlan])

  // Costo total
  const [total, setTotal] = useState(base)

  // Porcentaje de oferta
  const [totalOffer, setTotalOffer] = useState(total)
  const [discount, setDiscount] = useState(0)
  const [porcent, setPorcent] = useState(0)

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      id: short.generate(),
      fecha: fecha ? fecha : '',
      viajeros: 1,
      total: totalOffer,
      paquete: id,
    },
    validationSchema: Yup.object({
      fecha: Yup.date().required('Este campo es Obligatorio'),
      viajeros: Yup.number('Debe Ingresar un Valor Valido')
        .required('Este campo es Obligatorio')
        .positive('Debe ser un valor positivo')
        .integer('Debe ser un valor entero'),
    }),
    onSubmit: (data) => {
      if ((cupo_min && cupo < cupo_min) || (cupo_max && cupo > cupo_max)) {
      } else {
        data.total = totalOffer
        createPlan(data)
      }
    },
  })

  // EFFECT
  // Actualiza el cupo y el total cada vez que se cambia el formulario
  useEffect(() => {
    setCupo(formik.values.viajeros + totalReservas)
    setTotal(formik.values.viajeros * base)
    // eslint-disable-next-line
  }, [formik.values.viajeros])

  useEffect(() => {
    verificarOferta(formik.values.viajeros)
    // eslint-disable-next-line
  }, [total])

  const verificarOferta = (viajeros) => {
    if (viajeros_rebaja) {
      let multiplo = Math.floor(getMultiplo(viajeros))
      if (multiplo > 0) {
        let rebaja = getPorcentajeRebaja(multiplo)
        setPorcent(rebaja)
        getRebaja(rebaja)
      } else {
        setPorcent(0)
        setDiscount(0)
        setTotalOffer(total)
      }
    } else {
      setTotalOffer(total)
    }
  }

  const getMultiplo = (num) => num / viajeros_rebaja
  const getPorcentajeRebaja = (num) => num * viajeros_porcentaje
  const getRebaja = (rebaja) => {
    let precioR = rebaja / 100
    let discount = total * precioR
    setTotalOffer(total - discount)
    setDiscount(discount)
  }

  // Asigna el total de cupos inicial con los viajeros del form y el total de reservas
  useEffect(() => {
    setCupo(formik.values.viajeros + totalReservas)
    // eslint-disable-next-line
  }, [totalReservas])

  return (
    <>
      <h1>Reserva al Instante</h1>

      <hr />
      <h2>Detalles del paquete:</h2>
      <h3>
        Nombre: <small>{nombre}</small>
      </h3>
      <h3>
        Tipo de Experiencia: <small>{categoria.nombre}</small>
      </h3>
      <h3>
        Destino:{' '}
        <small>{`${ciudad.nombre} - ${ciudad.departamento.nombre}`}</small>
      </h3>
      <h3>
        Fecha de Salida:{' '}
        <small>
          {formik.values.fecha !== '' &&
            Intl.DateTimeFormat('es-ES', {
              dateStyle: 'full',
              timeZone: 'GMT',
            }).format(new Date(formik.values.fecha))}
        </small>
      </h3>
      {cupo_max || cupo_min ? (
        <>
          <hr />
          {cupo_min ? (
            <h3>
              Cupo Mínimo:{' '}
              <small>
                {cupo_min}{' '}
                {cupo_min > 1 || cupo_min < 1 ? 'Viajeros' : 'Viajero'}
              </small>
            </h3>
          ) : null}
          {cupo_max ? (
            <h3>
              Cupo Máximo:{' '}
              <small>
                {cupo_max}{' '}
                {cupo_max > 1 || cupo_max < 1 ? 'Viajeros' : 'Viajero'}
              </small>
            </h3>
          ) : null}
          <h3>
            Cupo Actual:{' '}
            <small>
              {cupo} {cupo > 1 || cupo < 1 ? 'Personas' : 'Persona'}
            </small>
          </h3>
          <h3>
            Total de Reservas:{' '}
            <small>
              {totalReservas}{' '}
              {totalReservas > 1 || totalReservas < 1 ? 'Viajeros' : 'Viajero'}
            </small>
          </h3>
        </>
      ) : null}
      <h3>
        Viajeros: <small>{formik.values.viajeros}</small>
      </h3>
      <hr />

      <h3>
        Costo Base:{' '}
        <small>${Intl.NumberFormat('es-ES').format(base)} pesos</small>
      </h3>
      {viajeros_rebaja ? (
        <>
          <h3>
            Descuento:{' '}
            <small>${Intl.NumberFormat('es-ES').format(discount)} pesos</small>
          </h3>
          <h3>
            Porcentaje de descuento: <small>{porcent}%</small>
          </h3>
        </>
      ) : null}
      <h3>
        Total:{' '}
        <small>${Intl.NumberFormat('es-ES').format(totalOffer)} pesos</small>
      </h3>
      <hr />
      <form onSubmit={formik.handleSubmit}>
        {!fecha ? (
          <div className="form-group">
            <label htmlFor="fecha">Fecha de mi Experiencia:</label>
            <input
              type="date"
              className="form-control"
              name="fecha"
              id="fecha"
              placeholder="elmercurio@mail.com"
              value={formik.values.fecha}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.fecha && formik.errors.fecha ? (
              <FormDanger msg={formik.errors.fecha} />
            ) : null}
          </div>
        ) : null}
        <div className="form-group">
          <label htmlFor="viajeros">Numero de Viajeros:</label>
          <input
            type="number"
            className="form-control"
            name="viajeros"
            id="viajeros"
            placeholder="0 Viajeros"
            value={formik.values.viajeros}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.viajeros && formik.errors.viajeros ? (
            <FormDanger msg={formik.errors.viajeros} />
          ) : null}
        </div>

        {(cupo_max && cupo > cupo_max) || (cupo_min && cupo < cupo_min) ? (
          <button type="submit" className="btn bnt-primary disabled btn-block">
            Cupo Incorrecto
          </button>
        ) : (
          <button
            type="submit"
            className={
              formik.values.viajeros > 0
                ? 'btn bnt-primary btn-block'
                : 'btn bnt-primary disabled btn-block'
            }
          >
            Reservar
          </button>
        )}
      </form>
    </>
  )
}

export default ReservaContainer
