import Encabezado from './experiencia/Encabezado'
import mountains from '../assets/mountains.jpg'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../context/auth/AuthContext'
import { useContext, useEffect } from 'react'
import FormDanger from '../components/FormDanger'
import Casco from '../components/Casco'

const Signup = ({ history }) => {
  // ID 4

  // Context Auth
  const authContext = useContext(AuthContext)
  const { msg, register } = authContext

  // Formik para creacion
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      role: 4,
      status: 'active',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('Este campo es Obligatorio'),
      last_name: Yup.number()
        .positive('Ingrese un numero valido')
        .integer('Ingrese un numero valido')
        .required('Este campo es Obligatorio'),
      email: Yup.string()
        .email('Ingrese un email valido')
        .required('Este campo es Obligatorio'),
      password: Yup.string()
        .required('Este campo es obligatorio')
        .oneOf([Yup.ref('confirmPassword')], 'Las contraseñas no coinciden'),
      confirmPassword: Yup.string()
        .required('Este campo es obligatorio')
        .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden'),
    }),
    onSubmit: (data) => {
      register(data)
      formik.resetForm()
    },
  })

  useEffect(() => {
    if (msg.type === 'success') history.push('/login')
    // eslint-disable-next-line
  }, [msg])

  return (
    <>
      <Casco titulo="Crea tu cuenta" />
      <Encabezado foto={mountains} nombre="Crea tu cuenta" />
      <div className="container mt-3 mb-3">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 shadow-lg p-5 rounded">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="first_name">Nombres y Apellidos:</label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  id="first_name"
                  placeholder="Elmer Curio"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <FormDanger msg={formik.errors.first_name} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Teléfono:</label>
                <input
                  type="number"
                  className="form-control"
                  name="last_name"
                  id="last_name"
                  placeholder="319 294 2835"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <FormDanger msg={formik.errors.last_name} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo Electrónico:</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="elmercurio@mail.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormDanger msg={formik.errors.email} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password">Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="*********"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <FormDanger msg={formik.errors.password} />
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirmar Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="*********"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <FormDanger msg={formik.errors.confirmPassword} />
                ) : null}
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="politica"
                  required
                />
                <label className="form-check-label" htmlFor="politica">
                  Acepto la política de tratamiento y protección de datos
                </label>
              </div>
              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn btn-primary float-right btn-block mt-4"
                >
                  Crear Cuenta
                </button>
                <Link to="/login" className="text-dark">
                  ¿Ya tiene cuenta? Inicie Sesión.
                </Link>
              </div>
              {msg.status ? <FormDanger msg={msg.msg} /> : null}
            </form>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </>
  )
}

export default Signup
