import { useReducer } from 'react'
import MiplanContext from './MiplanContext'
import MiplanReducer from './MiplanReducer'
import clienteAxios from '../../config/clienteAxios'
import {
  CLEAN_MIPLAN,
  CREATE_MIPLAN,
  GET_MIPLAN,
  GET_RESERVA,
  GET_RESERVAS,
  GET_RESERVASPLAN,
  HANDLE_CLEAN,
} from '../../types'

const MiplanState = (props) => {
  const initialState = {
    listado: [],
    reserva: [],
    reservas: [],
    reservasPlan: [],
    plan: [],
    loading: true,
    loadingR: true,
    loadingRes: true,
  }

  const [state, dispatch] = useReducer(MiplanReducer, initialState)

  // Crear el plan a pagar
  const createPlan = async (data) => {
    try {
      const res = await clienteAxios.post('items/miplan?fields=*.*.*.*', data)
      dispatch({
        type: CREATE_MIPLAN,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  // Limpiar el plan a pagar
  const cleanPlan = () => {
    dispatch({
      type: CLEAN_MIPLAN,
    })
  }

  const getReserva = async (id) => {
    try {
      const res = await clienteAxios.get(
        `items/compra?filter[reference_sale.owner.id][eq]=${id}&filter[state_pol][eq]=4&fields=*.*.*.*&sort=-created_on`
      )
      dispatch({
        type: GET_RESERVA,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getReservasPlan = async (id, fecha) => {
    try {
      const res = await clienteAxios.get(
        `items/compra?filter[reference_sale.paquete.id][eq]=${id}&filter[state_pol]=4&filter[reference_sale.fecha][gte]=${fecha}&fields=*.*.*`
      )
      dispatch({
        type: GET_RESERVASPLAN,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const getReservas = async () => {
    handleClean()
    try {
      const res = await clienteAxios.get(
        `items/compra?filter[state_pol][eq]=4&fields=*.*.*.*&sort=-created_on`
      )
      dispatch({
        type: GET_RESERVAS,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  const handleClean = () => {
    dispatch({
      type: HANDLE_CLEAN,
    })
  }

  const getPlan = async (id) => {
    try {
      const res = await clienteAxios.get(
        `items/compra?filter[reference_sale.owner.id][eq]=${id}&fields=*.*.*.*&sort=-created_on`
      )
      dispatch({
        type: GET_MIPLAN,
        payload: res.data.data,
      })
    } catch (e) {}
  }

  return (
    <MiplanContext.Provider
      value={{
        listado: state.listado,
        reserva: state.reserva,
        reservas: state.reservas,
        reservasPlan: state.reservasPlan,
        loadingR: state.loadingR,
        loadingRes: state.loadingRes,
        loading: state.loading,
        plan: state.plan,
        createPlan,
        getPlan,
        getReserva,
        getReservas,
        getReservasPlan,
        cleanPlan,
      }}
    >
      {props.children}
    </MiplanContext.Provider>
  )
}

export default MiplanState
