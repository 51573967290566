import EmailReducer from './EmailReducer'
import clienteAxios from '../../config/clienteAxios'
import { useReducer } from 'react'
import { HANDLE_CLEAN, HANDLE_ERROR, SEND_EMAIL } from '../../types'
import EmailContext from './EmailContext'

const EmailState = (props) => {
  const initialState = {
    msg: {
      type: '',
      msg: '',
      status: false,
    },
  }

  const [state, dispatch] = useReducer(EmailReducer, initialState)

  // Send Email
  const sendEmail = async (data) => {
    try {
      await clienteAxios.post('items/consulta', data)
      const msg = {
        type: 'success',
        msg: 'Se ha enviado correctamente',
        status: true,
      }
      dispatch({
        type: SEND_EMAIL,
        payload: msg,
      })
      handleClean()
    } catch (e) {
      const msg = {
        type: 'danger',
        msg: 'No se ha enviado tu consulta',
        status: true,
      }
      dispatch({
        type: HANDLE_ERROR,
        payload: msg,
      })
      handleClean()
    }
  }

  const handleClean = () => {
    setTimeout(() => {
      dispatch({
        type: HANDLE_CLEAN,
      })
    }, 3000)
  }

  return (
    <EmailContext.Provider value={{ msg: state.msg, sendEmail }}>
      {props.children}
    </EmailContext.Provider>
  )
}

export default EmailState
