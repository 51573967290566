import img from '../assets/404.svg'
import Casco from '../components/Casco'

const Error404 = () => {
  return (
    <>
      <Casco titulo="404" />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 my-auto text-center">
            <h1>ERROR 404!</h1>
            <h2>Ups! Parece que no se ha encontrado lo que buscabas.</h2>
          </div>
          <div className="col-lg-12">
            <img
              loading="lazy"
              className="img-fluid"
              src={img}
              alt="Logo 404"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Error404
